//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'
import BenefitsBoomerang from '../../components/marketing/benefitsBoomerang'

const useCaseData = [
  {
    title: 'Drive action from your customers with visual social marketing',
    titleWeight: 'medium',
    titleColor: 'white-1',
    description:
      'Use image-rich social marketing to spur your customers to action — visiting your location, engaging with your content, and more',
    descriptionColor: 'white-3',
    image: 'industrySalons',
    iconShow: true,
    iconContext: 'mirror',
    // iconColor: 'white',
    iconfill: 'white',
    iconcolor: 'white',
    // lineClass: 'green-1',
    // bgcolor: 'dkgreen-8',
    // border: 'border-top-2-dkgreen-1',
    bggradient: 'bali',
    expandcol: 5,
  },
]
const featureData = {
  title: 'Engage your personal services customers with visual templates',
  titlecolor: 'black-4',
  mode: 'triovertical',
  bgcolor: 'grayblue-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Offers and specials',
          titlecolor: 'dkorange-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'dkorange-1',
          iconname: 'tag',
          iconcolor: 'dkorange',
          path: '/platform/offers-promotions/',
          description:
            'Drive repeat visits by your customers by delivering image-rich offers and promotions',
        },
        {
          title: 'Content marketing',
          titlecolor: 'pink-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'pink-1',
          iconname: 'newspaper',
          iconcolor: 'pink',
          path: '/platform/content-marketing/',
          description:
            'Engage your customers with tips-and-tricks, and other content created with a drag-and-drop visual asset creator',
        },
        {
          title: 'Customer reviews',
          titlecolor: 'dkblue-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'dkblue-1',
          iconname: 'megaphone',
          iconcolor: 'dkblue',
          path: '/platform/review-management/',
          description:
            'Ask your customers to provide reviews of your establishment by posting easily customized review templates',
        },
      ],
    },
  ],
}
const About = (props) => (
  <Layout>
    <Helmet
      title="Storylava - Personal Services - Visual Content Marketing"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how Storylava can help you drive more revenue with visual content marketing for your salon / personal service business',
        },
      ]}
    />

    <NavSubUsecases />

    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-xl-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium sky-1">
            Increase revenue for your personal services business with visual
            social marketing
          </h1>
          <p class="display-4">
            Acquire new customers and keep current ones coming back with offers,
            content marketing, inspiration and more
          </p>
        </div>
      </div>
    </div>

    <AlternatingCallout featureData={useCaseData} />

    {/* SELECTED FEATURES */}
    <FeatureQuad featureData={featureData} />
    {/* END SELECTED FEATURES */}
  </Layout>
)

export default About
